.button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--fw-medium);
  padding: 0.5rem 1.75rem;
  border-radius: .25rem;
  height: 2.75rem;
  outline: 0;
  cursor: pointer;
  color: var(--color-white);
  background-color: var(--color-blue-dark);
  border: 0;
  transition: all 300ms;
  text-decoration: none;
  user-select: none;
  pointer-events: auto;

  &.inverted {
    color: var(--color-blue-dark);
    background-color: var(--color-white);
    border: 1px solid var(--color-blue-dark);
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.4;
  }

  &:hover,
  &:focus {
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.9);
  }

  &.sm {
    height: 2.25rem;
    font-size: var(--fs-sm);
  }
}

.children {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  svg {
    flex-shrink: 0;
  }
}

.spinner {
  svg {
    fill: var(--color-white);
    width: 2rem;
    height: 2rem;
  }

  &.inverted svg {
    fill: var(--color-blue-dark);
  }
}

