.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.back {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--color-grey-dark);
  margin: -0.25rem;
  padding: 0.25rem;
  border-radius: 0.25rem;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  &:hover {
    color: var(--color-grey-darkest);
    background: var(--color-grey-lighter);
  }
}

.title {
  font-weight: var(--fw-medium);
  font-size: var(--fs-lg);
}

.header {
  margin-bottom: 0.5rem;
}

.description {
  margin-top: 0.75rem;
  color: var(--color-grey-dark);
  font-size: var(--fs-sm);
  line-height: 1.4;
}

.status {
  background: var(--color-red-lightest);
  padding: 0.75rem 1rem;
  border-radius: var(--border-radius);
  color: var(--color-red-darker);
  font-size: var(--fs-sm);
  line-height: 1.4;
}

.input {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 0.5rem 0;
}

.button {
  margin-top: 1.25rem;
  margin-bottom: 0.5rem;
}

.footer {
  margin-top: 0.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: var(--fs-sm);
  color: var(--color-grey-primary);
}

.message {
  margin-top: 1rem;
  text-align: center;
  font-size: var(--fs-sm);
  color: var(--color-grey-darkest);
  background: var(--color-grey-lightest);
  padding: 0.5rem;
  border-radius: var(--border-radius);
  line-height: 1.4;
}

.signUp {
  background-color: var(--color-grey-lightest);
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem -1.5rem -1.5rem;
  font-size: var(--fs-xs);
  gap: 0.25rem;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.logoutWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0;

  & > p {
    color: var(--color-grey-dark);
  }

  & > * {
    margin: 0;
  }
}

.imageBackground {
  height: 140px;
  width: 140px;
  display: flex;
  background: var(--color-blue-lighter);
  border-radius: 50%;

  svg {
    height: 85px;
    width: 85px;
    margin: auto;
  }
}
