.wrapper {
  --size: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:global {
    animation: fadeIn 1s;
  }

  svg {
    fill: var(--color-grey-primary);
    height: var(--size);
    width: var(--size);

    &:global {
      animation: spin 2s infinite;
    }
  }
}
