.wrapper {
  position: relative;
  min-height: 38px;
  /* min-height of react-select container */
}

.invalidIcon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  fill: var(--color-red-primary);
  width: 1.25rem;
  height: 1.25rem;
}

.validation-text {
  font-size: var(--fs-xs);
  color: var(--color-red-primary);
  position: absolute;
  top: 2.5rem;
  margin-left: 4px;
}

.focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--color-blue-primary);
  transition: all 300ms;

  &.focus:not(.disabled) {
    width: 100%;
  }

  &.invalid {
    background-color: var(--color-red-primary);
  }
}

.floating-label {
  position: absolute;
  display: block;
  margin: 2px 4px;
  left: 0px;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-grey-primary);
  transition: all 0.3s;
  z-index: 0;
  pointer-events: none;

  &.floating {
    margin-left: 2px;
    top: -10px;
    transform: none;
    font-size: var(--fs-xs);
    color: var(--color-grey-darkest);

    &.invalid {
      color: var(--color-red-primary);
    }
  }

  &.disabled {
    color: var(--color-grey-primary);
  }
}
