.wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0.5rem;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  background: var(--color-blue-lighter);
}

.connect {
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  fill: var(--color-grey-primary);
}

.avatar {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  fill: var(--color-blue-light);
  border-radius: 50%;
  padding: 0.5rem;
  background: var(--color-white);
  box-shadow: var(--shadow-xs);
}
