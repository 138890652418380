.input {
  position: absolute;
  bottom: 0;
  border: 0;
  padding: 6px 4px;
  border-bottom: 1px solid var(--color-grey-light);
  background-color: transparent;
  color: var(--color-grey-darkest);
  font-size: var(--fs-default);
  width: 100%;

  &:focus {
    outline: none;
  }

  &.invalid {
    padding-right: 35px;
  }

  &.hidePlaceholder::placeholder {
    opacity: 0;
  }

  &.hidePlaceholder:focus::placeholder {
    opacity: 1;
    color: var(--color-grey-primary);
    transition: 300ms;
  }

  &:disabled {
    opacity: 0.4;
  }
}
