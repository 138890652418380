.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('https://res.cloudinary.com/woopgroup/image/upload/v1658774441/assets/woop_bg_v2.png');
  background-size: 200px;
}

.content {
  margin: 0 auto;
  padding: 1.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: var(--color-white);
  max-width: var(--width-modal);
  width: 100%;
  box-shadow: var(--shadow-default);
  border-radius: var(--border-radius);
}
